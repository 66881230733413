<template>
  <div class="business-trip-checklist">
    <div
      class="btc__group"
      v-for="(group, name) in groupedChecklist"
      :key="`group_${name}`"
    >
      <h3 class="btc__group-title" v-html="group.title" />

      <template v-for="field in group.fields">
        <div
          class="ticket-checklist-item"
          :class="{ 'ticket-checklist-item--full': field.rows }"
          v-if="field.value || field.rows"
          :key="field.id"
        >
          <template v-if="field.value">
            <div class="ticket-checklist-item--label">
              {{ field.title }}
            </div>
            <div class="ticket-checklist-item--value">
              {{ field.valueText || field.value }}
            </div>
          </template>
          <div
            class="btc__row"
            v-else-if="field.rows"
            v-for="(row, index) in field.rows"
            :key="`row__${index}`"
          >
            <h4 class="btc__row-title" v-if="showSubtitle(field, index)">
              <span> {{ subtitleText(field) }} </span>
              <template
                v-if="showSubtitleNumber(field) && field.rows.length > 1"
              >
                - {{ index + 1 }}
              </template>
            </h4>
            <template v-for="item in row">
              <div
                v-if="item.value"
                class="ticket-checklist-item"
                :key="`row-item__${item.id}`"
              >
                <div class="ticket-checklist-item--label">
                  {{ item.title }}
                </div>
                <div class="ticket-checklist-item--value">
                  {{ valueText(item) }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CHECKLIST_DATA, CHECKLIST_GROUP } from '@/constants/businessTrip';

export default {
  name: 'BusinessTripChecklist',
  props: {
    checklist: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    groupedChecklist() {
      const value = {};
      Object.keys(CHECKLIST_GROUP).forEach((item) => {
        value[item] = {
          title: CHECKLIST_GROUP[item],
          fields: [],
        };
      });

      if (this.checklist && this.checklist.length) {
        this.checklist.forEach((item) => {
          const checkListItem = CHECKLIST_DATA[item.id];
          if (checkListItem && checkListItem.group) {
            value[checkListItem.group].fields.push(item);
          }
        });
      }

      return value;
    },
    valueText() {
      return (item) => {
        const checkListItem = CHECKLIST_DATA[item.id];
        if (checkListItem && checkListItem.values) {
          return checkListItem.values[item.value];
        }

        return item.valueText || item.value;
      };
    },
    subtitleText() {
      return (item) => {
        const checkListItem = CHECKLIST_DATA[item.id];
        if (checkListItem && checkListItem.checkListLabel) {
          return checkListItem.checkListLabel;
        }
        return item.title;
      };
    },
    loopOffset() {
      return (item) => {
        const checkListItem = CHECKLIST_DATA[item.id];
        if (checkListItem && checkListItem.loopOffset) {
          return checkListItem.loopOffset;
        }
        return 0;
      };
    },
    showSubtitle() {
      return (item, index) => {
        const checkListItem = CHECKLIST_DATA[item.id];
        if (checkListItem && checkListItem.showSubtitleOnce) {
          return !index;
        }
        return true;
      };
    },
    showSubtitleNumber() {
      return (item) => {
        const checkListItem = CHECKLIST_DATA[item.id];
        if (checkListItem && checkListItem.hideSubtitleNumber) {
          return false;
        }
        return true;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btc__group,
.btc__row {
  padding: 24px;
  border-radius: 12px;
  border: 1px #e8e8ee solid;
  background: #fff;
  margin-top: 20px;
  @include for-size(phone-portrait-down) {
    padding: 8px;
    margin-top: 12px;
  }
}
.btc__row-title {
  font-size: 16px;
  @include for-size(phone-portrait-down) {
    font-size: 14px;
  }
}
.ticket-checklist-item {
  font-size: 14px;
  margin-top: 20px;
  &--label {
    font-weight: bold;
  }
  &--value {
    margin-top: 4px;
  }

  @include for-size(phone-portrait-down) {
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
